.floating-label {
  position: relative;
  display: flex;
  // flex-flow: column-reverse;
  flex-flow: column;
  vertical-align: top;
  // margin-top: 12px;
  // margin-bottom: 0;
  margin-bottom: 16px;

  label {
    position: absolute;
    // top: 20px;
    top: 19px;
    left: 16px;
    margin-bottom: 0;
    transform-origin: left bottom;
    font-size: 16px;
    // font-family: $roboto-regular;
    color: $input-text-color;
    line-height: 1;
    pointer-events: none;
  }

  label,
  input {
    transition: 0.1s;
    touch-action: manipulation;
  }

  input:disabled {
    background-color: #F2F2F2;
    border-color: #BDBDBD !important;
    color: #BDBDBD !important;
  }
  input:disabled:placeholder-shown + label {    
    color: #BDBDBD !important;
  }
  

  input::-ms-clear,
  textarea::-ms-clear {
    display: none;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    opacity: 0 !important;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 0 !important;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    opacity: 0 !important;
  }

  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    opacity: 0 !important;
  }

  input:placeholder-shown + label,
  select:placeholder-shown + label,
  textarea:placeholder-shown + label {
    max-width: 80%;
    cursor: text;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0);
    font-size: 16px;
  }

  input.placeholder-shown + label,
  select.placeholder-shown + label,
  textarea.placeholder-shown + label {
    max-width: 80%;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0);
    font-size: 16px;
  }

  input:focus + label,
  input.placeholder-not-shown + label {
    transform: translate(0, -80%);
    cursor: pointer;
    font-size: 12px;
  }

  input:not(:placeholder-shown) + label {
    transform: translate(0, -80%);
    cursor: pointer;
    font-size: 12px;
  }

  select:placeholder-shown {
    color: hsla(0, 0%, 100%, 0);
  }

  select.placeholder-shown {
    color: hsla(0, 0%, 100%, 0);
  }

  input:focus,
  select:focus,
  textarea:focus {
    border-color: $input-border;
  }

  input:focus,
  input.placeholder-not-shown {
    padding-top: 12px;
  }

  input:not(:placeholder-shown) {
    padding-top: 12px;
  }

  textarea:focus,
  textarea.placeholder-not-shown {
    padding-top: 22px;
  }

  textarea:not(:placeholder-shown) {
    padding-top: 22px;
  }

  input:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label {
    transform: translate(0, -100%);
    cursor: pointer;
    font-size: 12px;

    &:after {
      font-size: 1.1rem;
      right: -7px;
    }
  }

  input:focus + label,
  input.placeholder-not-shown + label,
  select:focus + label,
  select.placeholder-not-shown + label,
  textarea:focus + label,
  textarea.placeholder-not-shown + label {
    transform: translate(0, -100%);
    cursor: pointer;
    font-size: 12px;

    &:after {
      font-size: 1.1rem;
      right: -7px;
    }
  }

  input:focus + label,
  select:focus + label,
  textarea:focus + label {
    color: $color-orange-medium;
  }

  input,
  select {
    height: 56px;
  }

  textarea {
    height: calc(5em + 44px);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  input,
  select,
  textarea {
    // font-family: $roboto-regular;
    padding: 0 16px;
    margin-bottom: 0;
    border: $input-border;
    border-radius: $input-border-radius;
    font-size: 16px;
    // line-height: 1.5;
    color: $input-text-color;
    background-color: $color-white;

    &:focus,
    &:hover,
    &:active {
      outline: none;
      border-color: $color-orange-medium !important;
      box-shadow: rgba(240, 128, 0, 0.14) 0px 5px 20px !important;

      label {
        color: $color-orange-medium;
      }
    }

    &:hover {
    }
  }

  select {
    outline: none;
    padding: 12px 16px 0;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M515.406 783.702l491.612-491.612-69.024-69.074-423.369 423.369-423.322-423.369-69.024 69.074 491.612 491.612 0.734-0.782z' style='fill: #EA650D'%3E%3C/path%3E%3C/svg%3E%0A");
    background-position: 95% center;
    background-repeat: no-repeat !important;
    background-size: 15px 15px;
    -moz-padding-start: 11px;

    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M515.406 783.702l491.612-491.612-69.024-69.074-423.369 423.369-423.322-423.369-69.024 69.074 491.612 491.612 0.734-0.782z' style='fill: #EA650D'%3E%3C/path%3E%3C/svg%3E%0A");
    }
  }

  // moje - temp?
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  option {
    color: #535353;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #535353;
  }

  &.floating-label-select {
    &.no-label {
      select {
        padding-top: 0;
      }
    }
  }

  &.error {
    color: $color-error-red;

    label {
      color: $color-error-red;
    }

    input {
      border-color: $color-error-red;

      &:focus {
        border-color: $color-error-red;
      }
    }
  }

  &.success {
    input:not(:focus),
    select:not(:focus),
    textarea:not(:focus) {
      border-color: #ccc;
    }
  }

  &.is-required {
    label {
      
      &:after {
        content: "•";
        position: absolute;
        right: -10px;
        top: 50%;
        color: $color-orange-medium;
        transform: translateY(-50%);
        font-size: 1.5rem;
      }
    }
  }
}
