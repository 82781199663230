.superbrands-icons-list {
  border-top: 1px solid #F2F2F2;
  padding: 32px 0 16px;

  h2 {
    font-size: 28px;
    line-height: 36px;
    font-family: NNNittiGrotesk-Heading;
    margin-bottom: 16px;

    @media screen and (max-width: 39.99875em) {
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (min-width: 40em) {
      &+p {
        width: 70%;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  .superbrands-list {
    margin: 16px 0 0;
    list-style: none;

    li {
      padding: 16px 0;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        border-bottom: 1px solid #F2F2F2;
      }

      .cotent-wrapper {
        margin-left: 24px;
        flex: calc(100% - 80px);
      }

      a {
        font-size: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      p {
        @media screen and (min-width: 40em) {
          width: 80%;
        }
      }

      p+p {
        margin: 8px 0 0;
      }
    }
  }
}