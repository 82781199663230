@media (max-width: 1023px) {
  .step-process-arrowed {
    .single-step {
      .arrow {
        background-position: bottom center !important;
      }
    }
  }
}

@media (min-width: 1024px) {
  .step-process-arrowed:not(.__vertical) {
    .single-step {
      position: relative;

      .counter + .step-description,
      .counter + .step-title {
        padding-top: 30px;
      }

      .arrow {
        top: 60px !important;
        right: 60px !important;
        left: 60px !important;
        display: block !important;
      }
    }
  }
}

@media (min-width: 1600px) {
  .step-process-arrowed:not(.__vertical) {
    .single-step {
      .arrow {
        top: 70px !important;
      }
    }
  }
}
