.maps-description {
  position: relative;
  margin: 56px 0;
  color: #414141;
  font-size: 16px;
  font-family: 'NNNittiGrotesk-TextRegular';
  line-height: 1.25;

  .map-legend {
    position: absolute;
    top: -160px;
    padding: 16px;
    background-color: #fff;
    font-family: 'NNNittiGrotesk-Heading';

    ul {
      margin: 0;
      list-style-type: none;
    }

    .legend-item {
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 12px;

      &:first-of-type {
        margin-bottom: 16px;
      }

      img {
        margin-right: 8px;
      }
    }
  }

  .maps-description-box {
    padding: 0 24px 24px;
    background-color: #F7F7F7;
    border-radius: 3px;

    @media (max-width: 1023px) {
      margin-bottom: 18px;
    }

    .svg-icon {
      transform: translateY(-8px);
    }

    .maps-desc-header {
      margin-top: 8px;
      margin-bottom: 16px;
      font-family: 'NNNittiGrotesk-Heading';
      font-size: 20px;
      line-height: 1.4;

      &.color-green {
        color: #347663 !important;
      }

      &.color-orange {
        color: #EA650D !important;
      }
    }

    .sub-header {
      margin-bottom: 16px;
    }

    .desc-list {
      margin-bottom: 16px;
    }

    hr {
      border: 1px solid #E0E0E0;
    }

    .btn {
      padding: 13px 24px;
      background-color: $btn-primary-bg-color;
      color: $btn-primary-text-color;
      font-size: 18px;
      line-height: 1.22;

      &.bordered {
        border: 1px solid $btn-secondary-border-color !important;
        color: $btn-secondary-text-color;
        background-color: transparent;
      }

      &:hover {
        background-color: $btn-hover-bg-color;
        color: $btn-hover-text-color;
      }

      &:active {
        box-shadow: $btn-active-shadow;
      }

    }

    .btn {

      &:first-of-type {
        margin-top: 24px;
      }

      &.bordered {
        margin-top: 8px;
      }
    }
  }
}