.map-filters {
  margin-top: 22px;

  fieldset {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: left;
      gap: 16px;
    }
  }

  input[type=radio] {
    display: none !important;
  }

  input[type=radio]+label:before {
    display: none !important;
  }

  input[type=radio]+label:after {
    display: none !important;
  }

  .filter-item {
    margin-bottom: 0;
    padding: 8px 12px;
    border-radius: 32px;
    border: 1px solid #E0E0E0;
    font-size: 12px;
    line-height: 1.33;

    .svg-icon {
      display: none;
      margin-left: 8px;
    }
  }

  input[type=radio]:checked {

    & + label {
      background-color: #E0E0E0;

      .svg-icon {
        display: inline-block;
      }
    }
  }
}
