.main-slider--slide {

  .banner-wrapper {

    &.banner-wrapper-ds {
      max-width: 430px;
      padding: 24px 48px;

      h1 {
        margin-bottom: 0;
      }

      .banner-wrapper--title {
        display: block;
        margin-bottom: 16px;
        color: $color-orange-medium !important;
        font-size: 40px;
        line-height: 1.1;
      }

      .content {

        p, li {
          font-size: 16px !important;
          line-height: 1.25;
        }

        ul {
          margin-left: 0;
          list-style-type: none;

          & > li {
            position: relative;
            margin-bottom: 8px;
            padding-left: 16px;
            padding-bottom: 0;

            &:before {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              top: 5px;
              left: 0;
              background-color: $color-orange-dark;
              border-radius: 8px;
            }
          }
        }
      }

      .banner-footer {
        display: block;
        margin-top: 32px;

        p {
          width: 100%;
        }

        .price-box {
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 1.25;

          strong {
            color: $color-orange-medium !important;
            font-size: 28px !important;
            line-height: 1.28 !important;
          }

          .tooltip-trigger {
            margin: 0;
            padding: 0;
            width: 18px;
            height: 18px;
          }
        }

        //.btn {
        //  width: 100%;
        //}
      }
    }
  }
}