/* VARIABLES
--------------------------------------------------------------------------
========================================================================*/

$transition-bezier: cubic-bezier(0.50, 0.50, 0.05, 0.95);
$cubic-bezier: cubic-bezier(0.07, 0.95, 0, 1);

/* Fonts
------------------------------------------------------------------------*/
$nngrotesk-text-regular: 'NNNittiGrotesk-TextRegular';
$nngrotesk-text-medium: 'NNNittiGrotesk-TextMedium';
$nngrotesk-text-bold: 'NNNittiGrotesk-TextBold';
$nngrotesk-text-heading: 'NNNittiGrotesk-Heading';

$nndagny-text-regular: $nngrotesk-text-regular;
$nndagny-text-light: $nngrotesk-text-regular;
$nndagny-display-normal: $nngrotesk-text-heading;

/* Actions types
------------------------------------------------------------------------*/
$actions-types: '&:hover', '&:not(:hover):focus';