@import "../../../design-system/modules/form-elements.scss";

.specialties-filter {
  width: 100%;
  position: relative;
  display: none;
  margin: 24px 0 32px;

  &.active-filter {
    display: block;
  }

  &.expanded {
    .floating-label:before {
      transform: rotate(180deg);
    }

    .specialties-options {
      height: auto;
      max-height: 500px;
    }
  }

  .floating-label {
    position: relative;
    margin-bottom: 0;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M515.406 783.702l491.612-491.612-69.024-69.074-423.369 423.369-423.322-423.369-69.024 69.074 491.612 491.612 0.734-0.782z' style='fill: #EA650D'%3E%3C/path%3E%3C/svg%3E%0A");
      background-repeat: no-repeat !important;
      background-size: cover;
      transition: transform 0.6s ease;
      pointer-events: none;
    }

    input {
      cursor: pointer !important;
    }
  }

  .specialties-options {
    max-height: 0;
    position: relative;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 2px 8px 0 rgba(65, 65, 65, 0.1),
      0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(65, 65, 65, 0.1);
    overflow: hidden;
    transition: all 0.7s ease-in-out;
    z-index: 2;

    .specialties-search {
      position: relative;
      padding: 16px 16px 8px;

      &:before {
        content: "";
        position: absolute;
        top: 28px;
        right: 28px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat !important;
        background-size: cover;
        transition: transform 0.6s ease;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_423_4016)'%3E%3Cpath d='M9.91697 14.1093L3 21M21 9.5C21 13.0899 18.0899 16 14.5 16C10.9102 16 8 13.0899 8 9.5C8 5.91015 10.9102 3 14.5 3C18.0899 3 21 5.91015 21 9.5Z' stroke='%23EA650D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_423_4016'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        pointer-events: none;
      }

      input {
        width: 100%;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .specialties-list {
      max-height: 500px;
      padding: 8px 0;
      overflow-y: auto;
      box-shadow: 0 -1px 4px 0 rgba(65, 65, 65, 0.05),
        0 -1px 2px 0 rgba(0, 0, 0, 0.1);

      .speciality-item {
        margin-bottom: 0;

        input:checked + label {
          font-weight: 700;
        }

        label {
          width: 100%;
          padding: 12px 16px;
          font-size: 16px;
          line-height: 1.5;
          text-align: left;

          &:first-letter {
            text-transform: uppercase;
          }

          &:before,
          &:after {
            left: unset;
            right: 16px;
            top: 12px;

            @media (max-width: 1023px) {
              top: 12px;
            }
          }

          &:after {

            @media (max-width: 1023px) {
              top: 3px;
            }
          }

          &:hover {
            background-color: $color-grey-white;
          }
        }
      }
    }
  }
}
