.insurance-offers {

  .insurance-offers-item {

    &.insurance-offers-item-ds {
      border-bottom: 1px solid $color-snow-grey;

      &:hover {
        border-color: $color-snow-grey;
      }

      .content {
        padding: 24px 48px 32px !important;
      }

      .flex-wrapper {

        &--upper {
          margin-bottom: 24px !important;

          .content--title {
            margin-bottom: 24px;
            color: $color-orange-medium;
            font-size: 28px;
            line-height: 1.28;
            text-align: left;

            a,
            button {
              color: $color-orange-medium;
              font-family: NNNittiGrotesk-Heading;
              font-size: 28px;
              line-height: 1.28;
              text-align: left;
            }
          }

          .list-simple {
            margin-left: 0;
            list-style-type: none;

            .list-item {
              position: relative;
              margin-bottom: 8px;
              padding: 0 0 0 16px;
              font-size: 16px;
              line-height: 1.25;

              &:before {
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: 5px;
                left: 0;
                background-color: $color-orange-dark;
                border-radius: 8px;
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          .link.arrowed {
            display: block;
            margin-top: 24px;
            padding: 24px;
            border-top: 1px solid $color-grey-light;
            border-bottom: 1px solid $color-grey-light;

            &:before {
              left: 6px;
            }

            &:hover {
              &:before {
                left: 10px;
              }
            }
          }
        }

        &--bottom {

          .price-wrapper {
            margin: 0;
            font-size: 16px !important;
            line-height: 1.25 !important;

            .price {
              text-align: left;
            }

            strong {
              color: $color-orange-medium !important;
              font-size: 28px !important;
              line-height: 1.28 !important;
            }
          }

          .button-wrapper {
            margin-top: 16px;

            .btn {
              width: 100% !important;
            }
          }
        }

        &.__wide {

          .flex-wrapper--upper,
          .flex-wrapper--bottom {
            width: calc(50% - 24px);
          }

          .flex-wrapper--upper {
            margin-bottom: 0 !important;

            .link.arrowed {
              padding-top: 0;
              padding-bottom: 0;
              border: none;
            }
          }

          .flex-wrapper--bottom {
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;

            .button-wrapper {
              min-width: 100%;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}