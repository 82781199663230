@import "map-filters.scss";
@import "map-description.scss";
@import "map-branch-template.scss";

.map {

  .input-wrapper {
    justify-content: center !important;

    .location-form {
      margin-bottom: 0;

      .search-wrapper {

        @media (max-width: 1023px) {
          display: flex;
          flex-direction: row;
        }
      }

      input[type=search] {
        font-size: 16px !important;
        padding: 20px 16px !important;

        @media (max-width: 1023px) {
          margin-bottom: 0 !important;
        }

        @media (min-width: 1024px) {
          width: 29rem !important;
        }
      }

      .search-button {
        margin-left: 8px;
        background-color: $btn-primary-bg-color;
        color: $btn-primary-text-color;
        font-size: 18px;
        font-family: 'NNNittiGrotesk-Heading';
        line-height: 1.22;

        @media (max-width: 1023px) {
          max-width: 125px;
          height: fit-content;
          padding: 18px 30px !important;
        }

        @media (min-width: 1024px) {
          padding: 20px 30px !important;
        }

        &:hover {
          background-color: $btn-hover-bg-color;
          color: $btn-hover-text-color;
        }

        &:active {
          box-shadow: $btn-active-shadow;
        }
      }
    }
  }

  #map {

    .gm-style-iw {
      max-height: unset !important;
      padding: 24px 48px !important;
      border-radius: 0 !important;
      box-shadow: 0px 1px 4px rgba(65, 65, 65, 0.1), 0px 3px 10px rgba(65, 65, 65, 0.1), 0px 10px 20px rgba(65, 65, 65, 0.1);

      .gm-style-iw-d {
        max-height: unset !important;
        display: inline;
      }

      .office-info-details {

        @media (min-width: 1024px) {
          width: 190px;
        }

        p,
        a {
          color: #414141;
          font-family: 'NNNittiGrotesk-TextRegular' !important;
          font-size: 16px;
          line-height: 1.25 !important;
        }

        .office-type {
          padding-bottom: 16px;
          border-bottom: 1px solid #E0E0E0;
        }

        .office-location {
          margin: 16px 0;
          line-height: 1.25;
        }

        .agent-prefix {
          margin-bottom: 8px;
        }

        .agent-info {
          margin-bottom: 16px;
        }
      }
    }
  }

  .agency-group {

    .offices-list-header {
      margin-bottom: 56px;
      color: #414141;
      font-size: 28px;
      font-family: 'NNNittiGrotesk-Heading';
      line-height: 1.28;
    }
  }
}