.agents-table {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .agent-cell {
    position: relative;
    width: 100%;
    padding: 16px 5px;

    @media (min-width: 640px) {
      width: 50%;
    }

    @media (max-width: 1023px) {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    @media (min-width: 1024px) {
      min-width: 33.333%;
      max-width: 33.333%;
      padding: 16px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, .12);
    }

    &:last-of-type {
      &:after {
        width: 300%;
      }
    }

    .agent-name {
      font-family: NNNittiGrotesk-Heading;
      font-size: 22px;
      line-height: 1.5;

      @media (min-width: 1024px) {
        font-size: 26px;
      }
    }

    .agent-email,
    .agent-phone {
      display: block;
      font-family: NNNittiGrotesk-TextRegular;
      font-size: 13px;
      line-height: 1.5;
    }
  }

  &.cms-mode {
    display: block !important;

    .agent-cell {
      width: 100% !important;
    }
  }
}