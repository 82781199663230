.ch2 .ch2-block {
  background: rgba(0, 0, 0, .25);
  mix-blend-mode: normal;
  backdrop-filter: blur(0) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 700000;
  display: flex;
  align-items: center;
  animation: fadeInFromNone 3s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0;
  }

  80% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }

}

.ch2 .ch2-theme-simple .ch2-settings {
  display: none;
  transform: inherit;
  max-height: calc(100vh - 2.5rem)
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-settings {
    max-height: calc(100vh - 6rem)
  }
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-settings {
    max-height: calc(100vh - 2rem)
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header {
  position: relative
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header .ch2-close-settings-btn {
  display: none !important;
}

.ch2-close-settings-btn {
  display: none !important;
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-deny-all-btn {
  margin: 0 0 2rem 0
}


@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-deny-all-btn {
    margin: 2rem 0
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-allow-all-btn {
  margin: 2rem 0 1rem 0;
  border: 1px solid #404040;
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-allow-all-btn {
    margin: 2rem 1.25rem 2rem 0;
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-allow-all-btn:hover {
  border: 1px solid transparent;
  background-color: #2b2b2b;
  color: #fff;
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options {
  border-top: 1px solid #e0e0e0
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row-reverse;
  padding: 1.375rem 0 1rem
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-settings-option-details p {
  margin-bottom: 0
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option #ch2-0-title,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option #ch2-1-title,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option #ch2-2-title {
  padding-bottom: .875rem
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch {
  position: absolute;
  top: 1rem
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch {
    position: unset
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch label {
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 17px;
  cursor: pointer;
  -webkit-transition: background .3s ease;
  transition: background .3s ease
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch label:before {
  content: " " !important;
  display: block !important;
  width: 1.75rem !important;
  height: 1.75rem !important;
  border-radius: 100% !important;
  position: relative !important;
  top: .125rem !important;
  left: .125rem;
  border: 0 !important;
  padding: 0 !important;
  -webkit-transition: left .3s ease-out;
  transition: left .3s ease-out;
  background-color: #fff;
  margin: 0
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch label:after {
  display: none
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input {
  opacity: 0;
  position: absolute;
  display: contents
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input+label {
  background-color: #9b9b9b;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5625rem;
  display: block !important;
  min-height: auto !important;
  padding: 0 !important
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input:disabled+label:before {
  background: #fff !important
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input:focus+label {
  outline-style: solid;
  outline-color: #666;
  outline-offset: .25rem;
  outline-width: thin
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input:checked+label {
  background-color: #ea650d;
  padding: 0 !important
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input:checked+label:before {
  left: 2.0625rem;
  margin: 0 !important
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-options .ch2-settings-option .ch2-switch input:checked+label:after {
  display: none
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-declaration p:first-child {
  margin-bottom: 1.125rem
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-declaration .ch2-learn-more {
  display: block
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container .ch2-settings-declaration .ch2-settings-options .ch2-header {
  padding-top: 22px;
  padding-bottom: 14px
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container>p:last-child {
  display: none
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-actions {
  padding-top: 2rem
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-actions>:first-child {
  display: none
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li {
  display: inline-block;
  margin-right: 1rem;
  padding-bottom: .6875rem
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li {
    margin-right: 1.5rem
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li .ch2-select-tab {
  font-size: 1rem
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li .ch2-select-tab {
    font-size: 1.25rem
  }
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li .ch2-select-tab:hover {
  color: #e64415
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul li .ch2-select-tab:focus {
  color: #414141
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs ul .active {
  border-bottom: 2px solid #ea650d
}

.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-content {
  height: auto;
  max-height: calc(100vh - 24rem);
  overflow: auto
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-content {
    max-height: calc(100vh - 22rem)
  }
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-content {
    max-height: calc(100vh - 26rem)
  }
}

.ch2 .ch2-theme-simple .ch2-dialog,
.ch2 .ch2-theme-simple .ch2-settings {
  position: fixed;
  background-color: #fff;
  z-index: 700001;
  top: 100px;
  left: 0;
  bottom: auto;
  width: 100%;
  padding: 0 0 1.5rem;
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog,
  .ch2 .ch2-theme-simple .ch2-settings {
    width: 58.5rem;
    padding: 5rem 0 3rem 0;
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -50%)
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-close-btn,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-close-btn,
.ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn {
  float: right;
  width: .875rem;
  height: .875rem;
  display: block;
  position: absolute;
  right: 1.875rem
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-close-btn,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-close-btn {
  margin-top: -3.75rem
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn,
.ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn {
  margin-top: -5rem
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn {
    margin-top: -5.75rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-close-btn:before,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-close-btn:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn:before {
  content: "";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ea650d;
  display: block;
  font-size: .875rem;
  position: relative;
  top: 2rem;
  left: .5rem;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-close-btn:before,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn:before,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-close-btn:before,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn:before {
    top: 1rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-close-btn:hover:before,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-close-settings-btn:hover:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-close-btn:hover:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-close-settings-btn:hover:before {
  color: #e64415
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content {
  max-height: calc(100vh - 16.5rem);
  overflow-y: auto
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content {
    max-height: calc(100vh - 19rem)
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content p:last-child,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content p:last-child {
  margin-bottom: 0
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-header,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header {
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-header,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header {
    padding-top: 2rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-header,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-tab-container,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-tabs,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-actions,
.ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs,
.ch2 .ch2-theme-simple .ch2-settings .ch2-settings-actions {
  padding-left: 1rem;
  padding-right: 1rem
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-content,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-header,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-tab-container,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-tabs,
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-settings-actions,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-dialog-content,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-header,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tab-container,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-tabs,
  .ch2 .ch2-theme-simple .ch2-settings .ch2-settings-actions {
    padding-left: 8.625rem;
    padding-right: 8.625rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog #ch2-dialog-title,
.ch2 .ch2-theme-simple .ch2-dialog #ch2-settings-title,
.ch2 .ch2-theme-simple .ch2-settings #ch2-dialog-title,
.ch2 .ch2-theme-simple .ch2-settings #ch2-settings-title {
  font-family: "NNNittiGrotesk-Heading";
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #ea650d;
  margin-bottom: 1rem
}

@media print,
screen and (min-width: 64em) {

  .ch2 .ch2-theme-simple .ch2-dialog #ch2-dialog-title,
  .ch2 .ch2-theme-simple .ch2-dialog #ch2-settings-title,
  .ch2 .ch2-theme-simple .ch2-settings #ch2-dialog-title,
  .ch2 .ch2-theme-simple .ch2-settings #ch2-settings-title {
    font-size: 2.5rem;
    line-height: 2.75rem;
    margin-bottom: 1.5rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog #ch2-dialog-description,
.ch2 .ch2-theme-simple .ch2-dialog #ch2-settings-description,
.ch2 .ch2-theme-simple .ch2-settings #ch2-dialog-description,
.ch2 .ch2-theme-simple .ch2-settings #ch2-settings-description {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.125rem
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more {
  position: relative;
  font-size: 1rem;
  padding-left: 1.25rem;
  margin-bottom: 1.125rem
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more:before {
  content: "";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 50%;
  color: #ea650d;
  transition: left .5s ease-in-out;
  line-height: 1;
  transform: translate(0, -50%)
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more:hover,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more:focus,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more:hover,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more:focus {
  color: #e64415
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more:hover:before,
.ch2 .ch2-theme-simple .ch2-dialog .ch2-learn-more:focus:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more:hover:before,
.ch2 .ch2-theme-simple .ch2-settings .ch2-learn-more:focus:before {
  left: .375rem;
  color: #e64415
}

.ch2 .ch2-theme-simple .ch2-dialog {
  max-height: calc(100vh - 2.5rem)
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-dialog {
    max-height: calc(100vh - 6.125rem)
  }
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-dialog {
    max-height: calc(100vh - 8rem)
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-actions {
  padding: 1rem 1rem 0 1rem
}

@media print,
screen and (min-width: 64em) {
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-actions {
    padding: 1rem 8.625rem 0 8.625rem
  }
}

.ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-actions .ch2-allow-all-btn {
  margin: 0 0 1rem 0
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-dialog .ch2-dialog-actions .ch2-allow-all-btn {
    margin: 0 1rem 0 0
  }
}

.ch2 .ch2-theme-simple .ch2-visible {
  display: block;
  -webkit-animation: ch2-default-bottom-in .8s ease-in;
  animation: ch2-default-bottom-in .8s ease-in
}


.ch2 .ch2-theme-simple .ch2-btn {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 1rem 1.25rem;
  text-align: center;
  font-family: "NNNittiGrotesk-Heading";
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1;
  overflow: hidden;
  border-radius: 3px;
  background-color: #404040;
  color: #fff;
  border: 1px solid transparent;
  transition: background-color .25s ease-in-out, color .25s ease-in-out;
  cursor: pointer;
}

@media print,
screen and (min-width: 40em) {
  .ch2 .ch2-theme-simple .ch2-btn {
    width: auto;
  }
}

.ch2 .ch2-theme-simple .ch2-btn-primary {
  background-color: #404040;
}

.ch2 .ch2-theme-simple .ch2-btn-primary:hover {
  background-color: #2b2b2b;
}

.ch2 .ch2-theme-simple .ch2-btn-secondary {
  border: 1px solid transparent;
  color: #404040;
  background-color: #fff;
}

.ch2 .ch2-theme-simple .ch2-btn-secondary:hover {
  border: 1px solid transparent;
  color: #2b2b2b;
  background-color: #fff;
  border: 1px solid #2b2b2b;
}

.ch2 .ch2-icon {
  width: 4.125rem
}

.ch2 .ch2-icon .ch2-open-settings-btn span {
  display: none
}

.ch2 .ch2-icon .ch2-open-settings-btn svg {
  fill: #ea650d
}

.ch2-dialog-close {
  display: none !important;
}