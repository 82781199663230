.map-luxmed {
  @import "includes/specialties-filter.scss";
  padding-top: 32px;
  padding-bottom: 44px;
  color: $color-grey-dark;

  ul {
    margin: 0;
    list-style-type: none;
  }

  .section-header-big {
    margin-bottom: 48px;
    color: $color-grey-dark;
    font-size: 32px;
    line-height: 1.25;
    text-align: center;

    @media (min-width: 760px) {
      font-size: 40px;
      line-height: 1.1;
    }
  }

  .location-form {
    margin-bottom: 0 !important;
    max-width: 660px;
    padding-bottom: 56px;

    .category-filter {
      margin-bottom: 32px;
      border-bottom: 1px solid $color-grey-light;
      text-align: left;

      .category-button {
        position: relative;
        padding-bottom: 12px;
        color: $color-grey-dark;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;

        &:first-of-type {
          margin-right: 32px;
        }

        &:after {
          display: none;
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 2px;
          background-color: $color-orange-medium;
        }

        &:hover,
        &.active-category {
          &:after {
            display: block;
          }
        }
      }
    }

    .search-wrapper {
      display: flex;

      input[type="search"]#autocomplete {
        width: 100% !important;
      }
    }

    .type-filter {

      fieldset {
        justify-content: flex-start !important;
        gap: 8px;
      }
    }

    .summary, .summary-zero {
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
    }

    .summary-zero {
      display: none;
      margin: 24px 0 0;
      text-align: center;

      &.active {
        display: block;
      }
    }
  }

  #map-luxmed {
    height: 440px;
    width: 100%;
  }

  .gm-style-iw {  // .marker-wrapper
    padding-left: 0px;
    border-radius: 3px;
  }
  .marker {
    max-width: 290px;
    padding: 0 48px 24px;
    color: $color-grey-dark;
    font-size: 16px;
    line-height: 1.25;
    box-shadow: 0px 10px 20px 0px #4141411A;
    box-shadow: 0px 3px 10px 0px #4141411A;
    box-shadow: 0px 1px 4px 0px #4141411A;    

    span {
      display: block;
    }

    a {
      font-family: $nngrotesk-text-regular;
    }

    .type {
      margin-bottom: 8px;
      color: $color-grey-medium;
      font-size: 10px;
      line-height: 1.6;
      text-transform: uppercase;
    }

    .name {
      padding-bottom: 16px;
      border-bottom: 1px solid $color-grey-light;
    }

    .address {
      margin: 24px 0;
    }

    .link {
      margin-top: 24px;
    }
  }

  .results-header {
    margin-top: 32px;
    margin-bottom: 32px;
    color: $color-grey-dark;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.28;
  }

  #results {
    margin-top: 32px;
    margin-bottom: 56px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    p.zero-found{
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
    }

    .facility-details {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      border-radius: 3px;
      background-color: #f7f7f7;
      color: $color-grey-dark;
  
      .address-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
  
        .address-city {
          font-size: 20px;
          line-height: 1.4;
          font-weight: 600;
        }
  
        .address-type {
          display: inline-block;
          margin-left: 8px;
          padding: 8.5px 12px;
          border-radius: 32px;
          background-color: #d2e9e4;
          font-size: 10px;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
  
          &.type-coop {
            color: #ea650d;
            background-color: #fff7ee;
          }
        }
      }
  
      .address-name {
        margin-bottom: 16px;
        color: #414141;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.28;
      }
  
      .address-street {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
      }
  
      .address-contact {
        display: flex;
        justify-content: space-between;
  
        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 1.5;
        }

        .address-phone {
          a {
            font-size: 16px;
            line-height: 1.5;
            font-family: $nngrotesk-text-regular;
          }
        }

        .address-links {
          li:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    #page-info {
      margin-right: 16px;
      font-size: 16px;
      line-height: 1.5;
    }

    .pagination-btn {
      width: 32px;
      height: 32px;
      margin: 0 8px;
      border: 1px solid $color-grey-dark;
      border-radius: 32px;

      &[disabled] {
        background-color: $color-snow-white;
        border-color: $color-grey-light;
        cursor: not-allowed;
      }

      img {
        width: 16px;
        height: 16px;
      }

      &#first-page,
      &#prev-page {
        img {
          transform: rotate(180deg);
        }
      }
    }

    #page-select {
      width: 82px;
      // height: 32px;
      margin: 0 8px;
      padding: 4px 16px;
      border: 1px solid $color-grey-light;
      border-radius: 3px;
      outline: none;
      font-size: 16px;
      line-height: 1.5;
    }

    select {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M515.406 783.702l491.612-491.612-69.024-69.074-423.369 423.369-423.322-423.369-69.024 69.074 491.612 491.612 0.734-0.782z' style='fill: #EA650D'%3E%3C/path%3E%3C/svg%3E%0A");
      background-position: calc(100% - 12px) center;
      background-repeat: no-repeat !important;
      background-size: 15px 15px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";

      &:focus {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M515.406 783.702l491.612-491.612-69.024-69.074-423.369 423.369-423.322-423.369-69.024 69.074 491.612 491.612 0.734-0.782z' style='fill: %236B42A6'%3E%3C/path%3E%3C/svg%3E%0A");
      }
    }
  }
}
